import React from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import LoginIcon from '@mui/icons-material/Login';
import { Typography } from '@mui/material';

export const Main = () => {

    const oauth = () => {
        const { REACT_APP_LOGIN_ENDPOINT } = process.env;
        window.open(REACT_APP_LOGIN_ENDPOINT, '_blank');
    }

    return (
        <section
            style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            <div className='box-main'>
                <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    direction={"row"}
                >
                    <Grid className="box-main-left" item xs={12}>
                        <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            direction={"column"}
                        >
                            <Grid item xs={12}>
                                <Typography variant="h2" id="main-title">Bienvenue !</Typography><br />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    direction={"row"}
                                >
                                    <Grid item xs={5}>
                                        <img
                                        height={100}
                                            src={'/images/OIQIA-LOGO.png'}
                                            alt={"Logo OIQIA"}
                                            loading="lazy"
                                        />
                                    </Grid>
                                    <Grid item xs={2}>X</Grid>
                                    <Grid item xs={5}>
                                        <img
                                        height={100}
                                            src={'/images/IGLOO-LOGO.jpg'}
                                            alt={"Logo OIQIA"}
                                            loading="lazy"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="p" style={{ fontStyle: 'italic', color: "#383736" }}>Liez votre compte Igloohome pour nous permettre de gérer vos appareils connectés !</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 20 }}>
                                <Button id="igloo-button" variant="contained" onClick={() => oauth()} startIcon={<LoginIcon />}>
                                    Se connecter avec IglooHome
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </div>
        </section>
    )
}

export default Main;